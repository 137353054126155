import { Injectable } from '@angular/core';
// tslint:disable-next-line
import OktaSignIn from '@okta/okta-signin-widget';

@Injectable({ providedIn: 'root' })
export class OktaService {
  widget;

  constructor() {}

  getWidget(oktaUrl, forgotPasswordlink) {
    this.widget = new OktaSignIn({
      baseUrl: oktaUrl,
      redirectUri: window.location.hostname,
      authParams: {
        issuer: 'default',
      },
      features: {
        rememberMe: false,
      },
      helpLinks: {
        forgotPassword: forgotPasswordlink,
      },
      i18n: {
        en: {
          // tslint:disable
          'enroll.choices.description': 'Your company requires multifactor authentication to add an additional layer of security when signing in to your Pitney Bowes account',
        },
      },
    });
    return this.widget;
  }

}
