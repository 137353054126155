import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';

import { Constants } from '../constants';
import { Utils } from '../utils';
import { OriginValidatorService } from './origin-validator.service';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class TrustGuard implements CanActivate {

  originValidated = new BehaviorSubject<boolean>(false);

  constructor(public router: Router, private originValidatorService: OriginValidatorService) { }

  canActivate(): Promise<boolean> {
    // validates if required params are available in the URL
    return this.validateParams().then(() => {
      // validates the fromURI or TargetResource hostnames with the productId
      return this.validateOrigin().then((res) => {
        this.originValidated.next(true);
        return Promise.resolve(res);
      });
    });
  }

  private validateOrigin(): Promise<boolean> {
    const productId = Utils.getParam(Constants.PRODUCT_ID_PARAM);
    return this.originValidatorService.validateOrigin(productId).pipe(tap((r) => {
      this.originValidated.next(true);
      if (r['status'] || r['target']) {
        if (r.status && !r.target) {
          return true;
        } else if (r.status && r['target']) {
          return this.redirectForValidPage(r);
        }
      } else {
        this.redirectToErrorPage();
        return false;
      }
    }), catchError((e) => {
      // Validation failed with invalid redirect for logout URI
      if (window.location.pathname === Constants.ROOT_URI + Constants.LOGOUT_ROUTE) {
        this.redirectToDefaultLogoutPage();
      } else {
        // validation failed for TargetResources/fromURI
        this.originValidated.next(true);
        this.redirectToErrorPage();
        return of(false);
      }
    })).toPromise();
  }

  redirectForValidPage(r): boolean {
    switch (window.location.pathname) {
      case Constants.ROOT_URI + Constants.LOGIN_ROUTE:
        this.redirectToInterstitialPage();
        window.location.href = r.target;
        return false;
      case Constants.ROOT_URI + Constants.CLAIM_ACCOUNT_ROUTE:
      case Constants.ROOT_URI + Constants.RESET_PASSWORD_ROUTE:
      case Constants.ROOT_URI + Constants.RECLAIM_ACCOUNT_ROUTE:
        Utils.setParam(Constants.TARGET_RESOURCE_PARAM, r.target);
        return true;
      default:
        return r.status;
    }
  }

  redirectToErrorPage() {
    const id = Utils.getTransactionId();
    const url = '/' + Constants.ERROR_ROUTE + '?errorCode=403&id=' + id;
    this.router.navigateByUrl(url);
  }

  redirectToDefaultLogoutPage() {
    const url = '/' + Constants.LOGOUT_ROUTE + '?redirect=' + Constants.DEFAULT_LANDING_PAGE;
    window.location.href = window.location.origin + url;
  }

  redirectToInterstitialPage() {
    const url = '/' + Constants.LOADING_ROUTE;
    this.router.navigateByUrl(url);
  }

  /**
   * @private @function validateParams function to validate the query params and
   * set default params if appropriate parameters are not available
   * @return void
   */
  private async validateParams() {
    const pathname = window.location.pathname;
    if (pathname === Constants.ROOT_URI + Constants.ERROR_ROUTE
      || pathname === Constants.ROOT_URI + Constants.LOADING_ROUTE) {
      return;
    }
    // when productId param is not available
    if (Utils.getParam(Constants.PRODUCT_ID_PARAM) === null
      && pathname !== Constants.ROOT_URI + Constants.LOGOUT_ROUTE) {
      this.redirectToErrorPage();
    }
  }

}
