/**
 * @class LocaleConstants LOCALES with array of locales and its associated flags
 */
export class LocaleConstants {

    static readonly LOCALES = {
        locales: [{
            code: 'cs',
            name: 'Čeština',
        },
        {
            code: 'da',
            name: 'Dansk',
        },
        {
            code: 'de',
            name: 'Deutsch',
        },
        {
            code: 'en-au',
            name: 'English (Australia)',
        },
        {
            code: 'en-ca',
            name: 'English (Canada)',
        },
        {
            code: 'en-ie',
            name: 'English (Ireland)',
        },
        {
            code: 'en-nz',
            name: 'English (New Zealand)',
        },
        {
            code: 'en-gb',
            name: 'English (United Kingdom)',
        },
        {
            code: 'en-us',
            name: 'English (United States)',
        },
        {
            code: 'es-us',
            name: 'Español (Estados Unidos)',
        },
        {
            code: 'es',
            name: 'Español (España)',
        },
        {
            code: 'fr-ca',
            name: 'Français (Canada)',
        },
        {
            code: 'fr',
            name: 'Français (France)',
        },
        {
            code: 'it',
            name: 'Italiano',
        },
        {
            code: 'nl',
            name: 'Nederlands (Nederland)',
        },
        {
            code: 'no',
            name: 'Norsk',
        },
        {
            code: 'pl',
            name: 'Polski',
        },
        {
            code: 'pt-br',
            name: 'Português (Brasil)',
        },
        {
            code: 'ru',
            name: 'Русский',
        },
        {
            code: 'fi',
            name: 'Suomi',
        },
        {
            code: 'sv',
            name: 'Svenska',
        },
        {
            code: 'tr',
            name: 'Türkçe',
        },
        {
            code: 'he',
            name: 'עברית',
        },
        {
            code: 'ja',
            name: '日本語',
        },
        {
            code: 'zh-cn',
            name: '中文 (简体)',
        },
        ],
    };

    static readonly FLAGS = { flags: [] };

}
