// import 'url-search-params-polyfill';

import * as moment from 'moment-timezone';
import { CookieService } from 'ngx-cookie-service';
import { noop } from 'rxjs';
import { get } from 'scriptjs';

import { Constants } from './constants';
import { LocalStorageWrapper } from './local-storage-wrapper';
import { LocaleConstants } from './locale-constants';

/**
 * @class Utils Utility class for SaaS Login UI
 */
export class Utils {

    constructor(private cookieService: CookieService) { }
    /**
     * @public @static @function isEmpty function to check if an Object/String/Array is empty
     * @param value accepts Object/String/Array
     * @return boolean
     */
    public static isEmpty(value): boolean {
        if (value === undefined || value === null || value === 'undefined' || value.length === 0) {
            return true;
        }
        if (typeof value === 'string' && value.trim().length === 0) {
            return true;
        }
        if (typeof value === 'object') {
            return this.isEmptyObject(value);
        }
        return false;
    }

    /**
     * @public @static @function getCurrentESTDateTimeInISOFormat
     * function to get current EST time using moment.js library
     * @return EST Timestamp in ISO Format
     */
    public static getCurrentESTDateTimeInISOFormat() {
        return moment.tz(new Date(), 'America/New_York');
    }

    /**
     * @private @static @function isEmptyObject function to check if an object is empty
     * @param obj accepts object
     * @return boolean
     */
    private static isEmptyObject(obj) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    /**
     * @static @function getApiUrl function to get API URL
     * @return string | undefined, returns API URL
     */
    static getApiUrl(): string | undefined {
        const host = window.location.host.split('.');
        if (window
            && 'location' in window
            && 'protocol' in window.location
            && 'host' in window.location) {
            switch (host[0]) {
                case 'localhost:4200':
                    return '';
                    break;
                case 'login2-pre':
                    let preURL = 'https://' + window.location.host.replace('login2-pre', 'login2-api-pre');
                    preURL = preURL.replace('pitneycloud.com', 'pitneybowes.com');
                    return preURL;
                    break;
                case 'login2-test-dev':
                    return 'https://' + window.location.host.replace('login2-test-dev', 'login2-api-dev');
                    break;    
                case 'login2':
                    return 'https://' + window.location.host.replace('login2', 'login2-api'); 
                    break;
                default:
                    return 'https://' + window.location.host.replace('login2', 'login2-api');
            }
        }
        return undefined;
    }

    /**
     * @static @function getParam function
     * @param param to get query parameter from URL
     * @return string | null, returns query param value if available or null if not available
     */
    static getParam(param: string): string | null {
        // route to error page if required params are not available in the URL
        if (window.location.search) {
            const search = new URLSearchParams(window.location.search);
            const value = search.get(param);
            return value && value !== 'null' && value !== 'undefined' ? value : null;
        }
        return null;
    }

    /**
     * @static @function setParam function
     * @param {string} param to set query parameter in URL
     * @param {string} value to set value to the query param
     * @return {string} newly added param
     */
    static setParam(param: string, value: string): string {
        // route to error page if required params are not available in the URL
        if (this.getParam(param) === null) {
            const search = new URLSearchParams(window.location.search);
            search.append(param, value);
            return (window.location.href.indexOf('?') <= -1 ? '?' : '&') + param + '=' + value;
        }
    }

    /**
     * @static @function getOktaUrl function
     * @param param to get query parameter from URL
     * @return string | null, returns query param value if available or null if not available
     */
    static replaceOktaUrl(param: any, url: string): string | null {
        if (url) {
            return url.replace(param['targetDomain'], param['newTargetDomain']);
        }
        return null;

    }

    /**
     * @static @function getCurrentLocale function
     * @param {cookieService} ngx-cookie-service
     * @return string | null, returns locale based on the precedence
     */
    static getCurrentLocale(): string | null {
        try {
            if (typeof window.localStorage === 'undefined' || localStorage === null) {
                // qtweb support
                const locale = Utils.getCurrentLocaleFaster(Constants.USER_SELECTED_LOCALE)
                    || Utils.getCurrentLocaleFaster(Constants.QUERY_PARAM_LOCALE)
                    || Utils.getCurrentLocaleFaster(Constants.BROWSER_LOCALE)
                    || Constants.DEFAULT_LOCALE;
                return locale ? locale.replace(/\"/gi, '') : null;
            } else {
                return localStorage.getItem(Constants.USER_SELECTED_LOCALE)
                    || localStorage.getItem(Constants.QUERY_PARAM_LOCALE)
                    || localStorage.getItem(Constants.BROWSER_LOCALE)
                    || Constants.DEFAULT_LOCALE;
            }

        } catch (e) {
            // no localStorage supported, use default locale
            return Constants.DEFAULT_LOCALE;
        }
    }

    /**
     * @static @function getCurrentLocaleFaster function
     * Introduced as part of QTWeb support
     * This is needed so that we don't worry about returning promise object from CookieService
     * @param {cookieService} ngx-cookie-service
     * @return string | null, returns locale based on the precedence
     */
    static getCurrentLocaleFaster(cname: string): string | null {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        let locale = null;
        ca.forEach((c) => {
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                locale = c.substring(name.length, c.length);
            }
        });
        return locale;
    }

    /**
     * @static @function getLocalCodes function
     * @return Array of locale codes
     */
    static getLocalCodes(): any[] {
        const localeCodes = [];
        LocaleConstants.LOCALES['locales'].forEach((element, index) => {
            localeCodes.push(element['code']);
        });
        return localeCodes;
    }

    /**
     * @static @function getErrorCodes function to return error codes
     * @param err
     * @return errorCode or error status code
     */
    static getErrorCodes(err): any {
        return err['errorCode'] || (err && err['error'] && err['error']['status']
            ? err['error']['status'] : '0');
    }

    /**
     * @function setErrors function to set errors
     * @param err
     * @return errorCode with error message
     */
    static getErrors(err): object {
        const error = { errorCode: '-1', error: '' };
        error['errorCode'] = err['errorCode'] || (err && err['error'] && err['error']['status']
            ? err['error']['status'] : 0);
        error['message'] = err && err['message'] ? err['message'] : err;
        return error;
    }

    /**
     * @function isCookieEnabled function to check if cookies are enabled or not
     * @return cookiesEnabled boolean
     */
    static isCookieEnabled(): boolean {
        try {
            document.cookie = 'loginCookie=1';
            const cookiesEnabled = document.cookie.indexOf('loginCookie=') !== -1;
            document.cookie = 'loginCookie=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
            return cookiesEnabled;
        } catch (e) {
            return false;
        }
    }

    /**
     * @function convertQueryStringToObject function to convert URL query strings to object
     * @return object
     */
    static convertQueryStringToObject() {
        const pairs = location.search.slice(1).split('&');
        const result = {};
        pairs.forEach((pair) => {
            const p = pair.split('=');
            result[p[0]] = decodeURIComponent(p[1] || '');
        });

        return JSON.parse(JSON.stringify(result));
    }

    /**
     * @function removeUrlParams function to remove query param from URL without refreshing the page
     * @return void
     */
    static removeUrlParams(params) {
        const search = new URLSearchParams(window.location.search);
        params.forEach((param) => {
            if (search.has(param)) {
                search.delete(param);
            }
        });
        return window.location.origin + window.location.pathname + '?' + search.toString();
    }
    /**
     * loadDTMScripts function to load DTM library
     */
    static loadDTMScripts() {
        window['DDO'] = {};
        get(Utils.getLaunchLibrary(), () => {
            Utils.loadLaunchProducts();
        });
    }

    static getLaunchLibrary(): string {
        if (location.hostname === 'login2.saase2e.pitneybowes.com') {
            return `//assets.adobedtm.com/launch-ENb232a11a8a18420fb9fdd0e8c2919712.min.js`;
        } else {
            return `//assets.adobedtm.com/launch-EN8f7ec7286ce24e31a4a4b7e71a0e0b69-staging.min.js`;
        }
    }

    static loadLaunchProducts() {
        let product = Utils.getParam('productId');
        // set to lowercase productId as we applications set productIDs in uppercase/camelcase
        if (product) {
            product = product.toLowerCase();
        }

        const pathname = location.pathname;
        if (typeof window['_satellite'] === 'undefined') {
            return;
        } else if (product && pathname) {
            Utils.setDDOSatellite(product, pathname);
        }
    }

    /**
     * this functions get the X-PB-Transaction-Id for debugging purpose, used in trust guard service
     */
    static getTransactionId(): string {
        if (typeof window.localStorage === 'undefined' || localStorage === null) {
            return '';
        } else {
            return localStorage.getItem('X-PB-Transaction-Id');
        }
    }

    /**
     * this functions sets the X-PB-Transaction-Id for debugging purpose
     * @param transactionId
     */
    static setTransactionId(transactionId: string): void {
        if (typeof window.localStorage === 'undefined' || localStorage === null) {
            return;
        } else {
            localStorage.setItem('X-PB-Transaction-Id', transactionId);
        }
    }

    static setDDOSatellite(ddoProduct, pathname) {
        const deviceType = (/Mobi|Android/i.test(navigator.userAgent)) ? 'mobile' : 'desktop';

        // hard coding to US and USD for now, Saila will come back with solution
        window['DDO'].getBootstrap = 'US';
        window['DDO'].currencyCode = 'USD';

        window['DDO'].flowPath = ddoProduct + '||' + pathname;
        window['DDO'].campaign = window['_satellite'].getVar('campaign_cid');
        window['DDO'].globalData = {
            globalHier: 'pb| ' + ddoProduct + ' | ' + pathname,
        };
        window['DDO'].pageData = {
            pageHier: ddoProduct + '|' + pathname,
            pageName: 'ge|' + ddoProduct + '|' + pathname,
            pageRef: document.referrer, // Update with referrer url dynamically
            pageSiteSection: 'myaccount', // Site section ex: dashboard
            pageType: 'account/' + pathname, // ex: dashboard
            pageURL: location.href, // Update page URL dynamically
        };
        window['DDO'].siteData = {
            siteCountry: '', // Set country code dynamically ex: US
            siteDomain: document.domain, // Update with page domain
            siteFormat: deviceType, // Update dynamically
            siteLanguage: navigator.language, // Language ex: “en”
            siteRegion: '', // Region
        };
        window['DDO'].userData = {
            userID: '', // Any unique identifier for user
            oktaId: '', // Any unique identifier for user
            userNewReg: '', // New or registered user
            userStatus: 'Not Logged In',
            userType: '', // Type of user if any
        };
    }

    static unusualBrowser() {
        // qtweb support
        const agent = navigator && navigator.userAgent
            && navigator.userAgent.toLowerCase();
        const unusualBrowsers = ['qtweb', 'pbshell', 'demobrowser', 'qttestbrowser', 'qtemulator'];
        return unusualBrowsers.find((e) => {
            return agent.indexOf(e) >= 0;
        });
    }

    static setUnusualBrowserSettings() {
        /** support for QTWeb */
        if (Utils.unusualBrowser()) {
            const signInWrapper = document.getElementsByClassName('signin-wrapper')[0] as HTMLDivElement;
            signInWrapper && signInWrapper.style ? signInWrapper.style.margin = '40px auto !important' : noop();
            const footerElement = document.getElementsByClassName('qtweb-footer')[0] as HTMLDivElement;
            if (footerElement && !footerElement.classList.contains('fixed-bottom')) {
                footerElement.classList.add('fixed-bottom');
            }
            return true;
        } else {
            return false;
        }
    }
}
