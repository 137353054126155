import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Constants } from './shared/constants';
import { TrustGuard } from './shared/services/trust-guard.service';
import { SplashScreenComponent } from './shared/splash-screen/splash-screen.component';
import { LogoutComponent } from './logout/logout.component';

/**
 * Define list of routes used in the LOGIN_ROUTE
 * @return {Routes} navigation route
 */
const LOGIN_ROUTE = '/' + Constants.LOGIN_ROUTE + '?' + Constants.PRODUCT_ID_PARAM + '=' + Constants.APP_NAME
  + '&' + Constants.TARGET_RESOURCE_PARAM + '=' + Constants.DEFAULT_LANDING_PAGE;

const routes: Routes = [
  { path: 'loading', component: SplashScreenComponent },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
    canActivate: [TrustGuard]
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [TrustGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
    canActivate: [TrustGuard]
  },
  {
    path: 'forgot-password/success',
    loadChildren: () => import('./forgot-password/success/forgot-password-success.module').then((m) => m.ForgotPasswordSuccessModule),
    canActivate: [TrustGuard]
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
    canActivate: [TrustGuard]
  },
  {
    path: 'reset-password/success',
    loadChildren: () => import('./reset-password/success/reset-password-success.module').then((m) => m.ResetPasswordSuccessModule),
    canActivate: [TrustGuard]
  },
  {
    path: 'claim-account',
    loadChildren: () => import('./claim-account/claim-account.module').then((m) => m.ClaimAccountModule),
    canActivate: [TrustGuard]
  },
  {
    path: 'reclaim-account',
    loadChildren: () => import('./reclaim-account/reclaim-account.module').then((m) => m.ReclaimAccountModule),
    canActivate: [TrustGuard]
  },
  {
    path: 'reclaim-account/success',
    loadChildren: () => import('./reclaim-account/success/reclaim-account-success.module').then((m) => m.ReclaimAccountSuccessModule),
    canActivate: [TrustGuard]
  },
  {
    path: 'error',
    loadChildren: () => import('./shared/error/error.module').then((m) => m.ErrorModule),
    canActivate: [TrustGuard]
  },
  {
    path: '',
    redirectTo: LOGIN_ROUTE,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
