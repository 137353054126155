import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../constants';
import { Utils } from '../utils';

/**
 * @class SessionService service class to read cookies and session details from login-api
 * @see https://angular.io/api/core/Injectable
 */
@Injectable({ providedIn: 'root' })
export class SessionService {
  /**
   * @constructor for SessionService
   * @param {HttpClient} http, to call session service
   */
  constructor(private http: HttpClient) { }

  /**
   * @function getSessionDetails function to Call backend service to get cookies and session details
   * @see https://angular.io/guide/observables
   * @see https://www.typescriptlang.org/docs/handbook/basic-types.html
   * @return Observable<any> | never
   */
  getSessionDetails(): Observable<any> | never {
    const endpoint =
      Utils.getApiUrl() +
      Constants.LOGIN_SERVICES_API +
      Constants.API_VERSION +
      Constants.SESSION_DOMAIN;
    return this.http.post(endpoint, {}).pipe(map((response: HttpResponse<any>) => response));
  }

}
